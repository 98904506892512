<template>
  <div class="ClientDetails">
    <CModal
      :show.sync="isClientModified"
      :no-close-on-backdrop="true"
      title="Client modifié"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre client a bien été modifié 👍
      </p>
      <p>
        Vous avez bien modifié votre client. Vous pouvez rester sur votre page client ou retourner à la liste des clients de votre cabinet.
      </p>
      <template #footer>
        <CButton @click="isClientModified = false" color="primary">Continuer les modifications</CButton>
        <CButton @click="$router.push('/manage-clients/')" color="dark">Retour à la liste des clients</CButton>
      </template>
    </CModal>
    <CModal
      :show.sync="isClientArchived"
      :no-close-on-backdrop="true"
      title="Client archivé"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        ⛔ Votre client a bien été archivé ⛔
      </p>
      <p>
        Aucun collaborateur ne pourra plus créer de mission ni de contact associé à ce client.
      </p>
      <template #footer>
        <CButton @click="isClientArchived = false" color="primary">Continuer les modifications</CButton>
        <CButton @click="$router.push('/manage-clients/')" color="dark">Retour à la liste des clients</CButton>
      </template>
    </CModal>
    <CModal
      :show.sync="isClientActivated"
      :no-close-on-backdrop="true"
      title="Client réactivé"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre client a bien été réactivé 🥳
      </p>
      <p>
        Tous vos collaborateurs le verront dans la liste des clients et pourront créer des missions et des contacts
        associés à ce client.
      </p>
      <template #footer>
        <CButton @click="isClientActivated = false" color="primary">Continuer les modifications</CButton>
        <CButton @click="$router.push('/manage-clients/')" color="dark">Retour à la liste des clients</CButton>
      </template>
    </CModal>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <!--<CToaster :autohide="5000">
      <CToast
        class="custom-toast"
        :show="isCreateClientToast"
      >
      <template #header>
        <strong class="text-primary">Création de votre accès client</strong>
      </template>
      <template #default>
        <strong>Ah, vous n'avez pas encore créé d'espace Eclerk à votre client !</strong><br>
        <small>Son espace en ligne lui permet d'échanger des documents avec vous, de télécharger ses factures et de voir
        l'avancement de ses missions.</small>

        <CButton
          color="outline-primary"
          shape="pill" block class="mt-4 px-4"
          size="sm"
          @click="$router.push('/create-client-access/' + client.id)">
          Créer un accès en ligne à mon client
        </CButton>


      </template>
      </CToast>
    </CToaster>-->

    <CRow class="mb-3">
      <CCol sm="8">
        <h1 v-if="client.is_firm"> Votre client - {{client.name}} </h1>
        <h1 v-else> Votre client - {{client.first_name}} {{client.last_name}} </h1>
      </CCol>
      <CCol sm="4" class="text-right align-self-center">
        <h1> Réf : C-{{client.id}} </h1>
      </CCol>
    </CRow>


    <CCard v-if="!client.is_active">
      <CCardHeader>
        <slot name="header">
          <strong>Votre client a été archivé</strong>
        </slot>
      </CCardHeader>

      <CCardBody>
        <CButton
          :color="activateClientButtonStyle"
          shape="pill" block class="px-4"
          @click='activateClient'
          :disabled="activateClientInProcess">
          <CSpinner size="sm" label="archive client spinner" v-if="activateClientInProcess"></CSpinner>
          {{ activateClientButtonText }}
        </CButton>
      </CCardBody>
    </CCard>

    <div v-if="userGroupContains(['MANAGER']) && client.is_active">
      <CCard v-if="client.user">
        <CCardHeader>
          <slot name="header">
            <strong> <CIcon class="mr-2" name="cil-share-alt" size="lg" /> Accès Eclerk de votre client</strong>
            <div class="card-header-actions">
              <router-link  :to="'/client-access/' + client.id">
                <small class="text-muted">Modifier ou révoquer l'accès client</small>
              </router-link>
            </div>
          </slot>
        </CCardHeader>

        <CCardBody>
          <CRow>
            <CCol>
              <strong>Votre client possède un accès Eclerk 👍</strong>
            </CCol>
          </CRow>
          <CRow class="mt-3">
            <CCol sm="6">
              <span>Nom d'utilisateur : </span><strong>{{client.user.username}}</strong>
            </CCol>
            <CCol sm="6">
              <span>Mot de passe : </span>
              <strong>
                <router-link :to="'/modify-client-password/' + client.id">
                  **********
                </router-link>
              </strong>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <span>Prénom du responsable : </span><strong>{{client.user.first_name}}</strong>
            </CCol>
            <CCol sm="6">
              <span>Nom du responsable : </span><strong>{{client.user.last_name}}</strong>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <span>Email du responsable : </span><strong>{{client.user.email}}</strong>
            </CCol>
          </CRow>
          <CRow class="mt-2">
            <CCol>
              ℹ️ Pour faire une demande de documents à votre client, rendez-vous sur votre tableau
              de bord <i>mission</i> et réalisez une demande dans la rubrique <i>Demandes de documents au client</i>
            </CCol>
          </CRow>
          <CRow class="mt-3">
            <CCol class="font-text-weigth text-white">
              <CButton color="primary" shape="pill" size="sm" @click="goToAccessClient">Que voit mon client ?  Visualiser son espace</CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>

      <CCard v-else-if="companyStripeProductId === stripeSmallProductId || companyStripeProductId === stripeMediumProductId">
        <CCardHeader>
          <slot name="header">
            <CRow>
              <CCol sm="6">
                <strong><CIcon class="mr-2" name="cil-share-alt" size="lg" /> Accès client à la plateforme</strong>
              </CCol>
              <CCol class="text-right align-self-center" sm="6">
                <CButton
                  color="outline-primary"
                  shape="pill"
                  @click="$router.push('/create-client-access/' + client.id)">
                  Créer un accès en ligne à votre client
                </CButton>
              </CCol>
            </CRow>
          </slot>
        </CCardHeader>

        <CCardBody>
          <p>Vous pouvez <strong>donner à votre client accès à la plateforme Eclerk.</strong>
            Celui-ci pourra alors :
            <ul>
              <li><strong>Accéder au tableau de bord de sa mission</strong>, voir les collaborateurs associés, l'avancement de la mission
              <li><strong>Visualiser l'état de sa facturation</strong> et télécharger directement ses factures</li>
              <li><strong>Télécharger ses réçus de paiement</strong></li>
              <li><strong>Charger des documents sur la plateforme</strong> pour que vous puissiez directement les récupérer
                (pour cela il est nécessaire de créer une demande de documents sur votre tableau de bord mission)</li>
            </ul>
          </p>

          <CRow class="mt-3">
            <CCol class="font-text-weigth text-white">
              <CButton color="primary" shape="pill" size="sm" @click="goToAccessClient">Que voit mon client ?  Visualiser son espace</CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>

      <CCard v-else>
        <CCardHeader>
          <slot name="header">
            <strong><CIcon class="mr-2" name="cil-share-alt" size="lg" /> Accès client à la plateforme</strong>
          </slot>
        </CCardHeader>

        <CCardBody>
          <CIcon name="cil-ban" class="text-danger" size="lg" /><strong> Malheureusement, votre abonnement ne permet pas de créer un accès Eclerk à votre client</strong>
        </CCardBody>
      </CCard>
    </div>

    <CCard>
      <CCardHeader>
        <slot name="header">
          <strong><CIcon class="mr-2" name="cil-copy" size="lg" />Missions associées à votre client</strong>
          <div class="card-header-actions">
            <router-link
              to="/add-new-mission">
              <span class="text-success"><CIcon name="cil-plus" /> Créer une nouvelle mission</span>
            </router-link>
          </div>
        </slot>
      </CCardHeader>

      <CCardBody>
        <CDataTable
          class="cursor-table"
          hover
          :items="missionsRender"
          :fields="missionsRenderFields"
          @row-clicked="goToMissionPage"
        >

        <template #types="{item}">
          <td>
           <span v-for="type in item.types" v-bind:key="type.id">{{type.name}} </span>
          </td>
        </template>

        <template #collaborateurs="{item}">
          <td>
            <em v-for="collaborateur in item.collaborateurs" v-bind:key="collaborateur.id">{{collaborateur.full_name}} </em>
          </td>
        </template>

        <template #description="{item}">
          <td>
            <small> {{item.description}}</small>
          </td>
        </template>

        <template #no-items-view>
          <div class="text-center">
            <em> Aucune mission associée</em>
          </div>
        </template>
        </CDataTable>

      </CCardBody>
    </CCard>


    <CCard>
      <CCardHeader>
        <slot name="header">
          <strong><CIcon class="mr-2" name="cil-user" size="lg" />Contacts associés à votre client</strong>

          <div class="card-header-actions">
            <router-link
              :to="{ name: 'Ajouter un nouveau contact' }">
              <span class="text-success"><CIcon name="cil-plus" /> Ajouter un nouveau contact</span>
            </router-link>
          </div>
        </slot>
      </CCardHeader>

      <CCardBody>
        <CDataTable
          class="cursor-table"
          hover
          :items="contactsRender"
          :fields="contactsRenderFields"
          :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
        >
        <template #phone_number="{item}">
          <td v-if="item.phone_number">
            +{{returnPhoneDial(item.phone_country_code)}} {{item.phone_number}}
          </td>
          <td v-else>
            <em>Non renseigné</em>
          </td>
        </template>
        <template #actions="{item}">
          <td>
            <CButton
             color="outline-success"
             square
             size="sm"
             @click="goToContactPage(item)"
           >
             <CIcon name="cil-arrow-circle-right"/>
           </CButton>

            <CButton
             class="ml-2"
             color="outline-danger"
             square
             size="sm"
             @click="unlinkContactFromClient(item.id)"
           >
             <CIcon name="cil-x-circle"/>  Délier
           </CButton>
          </td>
        </template>


        <template #no-items-view>
          <div class="text-center">
            <em> Aucun contact associé</em>
          </div>
        </template>

        </CDataTable>

      </CCardBody>
    </CCard>



    <CCard>
      <CCardHeader>
        <strong>Modifier votre client - {{client.name}}</strong>
      </CCardHeader>
      <CCardBody>
        <CForm>

          <CRow class="mb-2">
            <CCol sm="2">
              Votre client est
            </CCol>
            <CCol sm="10">
              <CInputRadioGroup
                :checked.sync="modifyClientIsFirm"
                label="Votre client est"
                :custom="true"
                :inline="true"
                :disabled="!client.is_active"
                :options='clientIsFirmArrayForm'
              />
            </CCol>
          </CRow>

          <div v-if="modifyClientIsFirm == 'true'">
            <CRow>
              <CCol sm="6">
                <CInput
                  label="Nom du client"
                  v-model="modifyClientName" type="text"
                  maxlength="150"
                  :disabled="!client.is_active"
                  @input="$v.modifyClientName.$touch()"
                  :isValid="$v.modifyClientName.$dirty ? !$v.modifyClientName.$error : null"
                  invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
                >
                </CInput>
              </CCol>
              <CCol sm="6">
                <CInput
                  label="Siren"
                  v-model="modifyClientSiren" type="text"
                  v-mask="'NNN NNN NNN'"
                  maxlength="11"
                  placeholder="XXX XXX XXX"
                  :disabled="!client.is_active"
                  @input="$v.modifyClientSiren.$touch()"
                  :isValid="$v.modifyClientSiren.$dirty ? !$v.modifyClientSiren.$error : null"
                  invalid-feedback="Le numéro de SIREN doit être composé de 9 chiffres"
                >
                </CInput>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="6">
                <CInput
                label="Représentant légal"
                v-model="modifyClientRepresentantLegal" type="text"
                maxlength="150"
                :disabled="!client.is_active"
                placeholder="Nom du représentant légal"
                @input="$v.modifyClientRepresentantLegal.$touch()"
                :isValid="$v.modifyClientRepresentantLegal.$dirty ? !$v.modifyClientRepresentantLegal.$error : null"
                invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
                >
                </CInput>
              </CCol>
              <CCol sm="6">
                <CInput
                  label="Forme sociale"
                  v-model="modifyClientFormeSociale" type="text"
                  maxlength="150"
                  :disabled="!client.is_active"
                  placeholder="Forme sociale de l'entreprise"
                  @input="$v.modifyClientFormeSociale.$touch()"
                  :isValid="$v.modifyClientFormeSociale.$dirty ? !$v.modifyClientFormeSociale.$error : null"
                  invalid-feedback="La forme sociale doit comporter entre 2 et 150 caractères"
                  >
                </CInput>
              </CCol>
            </CRow>

          </div>

          <div v-if="modifyClientIsFirm =='false'">
            <CRow >
              <CCol sm="6">
                <CInput
                  label="Prénom du client"
                  autocomplete="eclerk-no-autocomplete-input"
                  v-model="modifyClientFirstName" type="text"
                  maxlength="150"
                  placeholder="Prénom"
                  @input="$v.modifyClientFirstName.$touch()"
                  :isValid="$v.modifyClientFirstName.$dirty ? !$v.modifyClientFirstName.$error : null"
                  invalid-feedback="Le prénom doit comporter entre 2 et 150 caractères"
                >
                </CInput>
              </CCol>
              <CCol sm="6">
                <CInput
                  label="Nom du client"
                  autocomplete="eclerk-no-autocomplete-input"
                  v-model="modifyClientLastName" type="text"
                  maxlength="150"
                  placeholder="Nom"
                  @input="$v.modifyClientLastName.$touch()"
                  :isValid="$v.modifyClientLastName.$dirty ? !$v.modifyClientLastName.$error : null"
                  invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
                >
                </CInput>
              </CCol>
            </CRow>
          </div>



          <CRow>
            <CCol sm="6">
              <CInput
                label="Email (facultatif)"
                type="email"
                maxlength="150"
                :disabled="!client.is_active"
                placeholder="Email (facultatif)"
                v-model="modifyClientEmail"
                @input="$v.modifyClientEmail.$touch()"
                :isValid="$v.modifyClientEmail.$dirty ? !$v.modifyClientEmail.$error : null"
              >
              </CInput>
            </CCol>
            <CCol sm="6">
              <div role="group" class="form-group">
                <label for="modifyClientVuePhoneNumber" class>
                  Numéro de téléphone (facultatif)
                </label>
                <vue-phone-number-input
                  id="modifyClientVuePhoneNumber"
                  name="modifyClientVuePhoneNumber"
                  v-model="modifyClientPhoneNumber"
                  :default-country-code='modifyClientPhoneNumberResult.countryCode'
                  :preferred-countries="['FR', 'BE', 'DE']"
                  valid-color='#28a745'
                  error-color='#dc3545'
                  :translations="{
                    countrySelectorLabel: 'Code pays',
                    countrySelectorError: 'Choisir un pays',
                    phoneNumberLabel: 'Numéro de téléphone',
                    example: 'Exemple :'
                  }"
                  @update='modifyClientPhoneNumberResult = $event'
                  :required='false'
                />
                <small class="text-danger" v-if="modifyClientPhoneNumberResult.isValid == false">Veuillez rentrer un numéro de téléphone valide</small>
              </div>
            </CCol>
          </CRow>

          <CRow class="mb-2">
            <CCol sm="3">
              L'adresse du client est une adresse :
            </CCol>
            <CCol sm="9">
              <CInputRadioGroup
                :checked.sync="modifyClientIsDomiciliation"
                :custom="true"
                :inline="true"
                :options='clientIsDomiciliationArrayForm'
              />
            </CCol>
          </CRow>


          <form autocomplete="eclerk-no-autocomplete-input">
            <label for="eclerk-modify-client-address">Adresse du client</label>
            <vue-google-autocomplete
              autocomplete="eclerk-no-autocomplete-input"
              ref="eclerkModifyClientAddress"
              id="eclerk-modify-client-address"
              name="eclerk-modify-client-address"
              classname="form-control"
              maxlength="299"
              placeholder="Rechercher l'adresse"
              @placechanged="chooseGoogleMapsModifyClientAddress"
              @inputChange="modifyClientAddress = $event.newVal"
              :enable-geolocation='true'
            >
            </vue-google-autocomplete>
          </form>

          <hr>

          <CRow class="mb-3">
            <CCol sm="3">
              Le client est-il <strong>différent</strong> du  payeur ?
            </CCol>
            <CCol sm="9">
              <CInputRadioGroup
                :checked.sync="modifyClientIsDifferentPayeur"
                :custom="true"
                :inline="true"
                :options='booleanArrayForm'
              />
            </CCol>
          </CRow>

          <div v-if="modifyClientIsDifferentPayeur == 'true'">

            <CRow class="mb-2">
              <CCol sm="2">
                Le payeur est
              </CCol>
              <CCol sm="10">
                <CInputRadioGroup
                  :checked.sync="modifyClientPayeurIsFirm"
                  :custom="true"
                  :inline="true"
                  :options='clientIsFirmArrayForm'
                />
              </CCol>
            </CRow>

            <CRow v-if="modifyClientPayeurIsFirm =='true'">
              <CCol sm="6">
                <CInput
                  label="Siren"
                  autocomplete="eclerk-no-autocomplete-input"
                  v-model="modifyClientPayeurSiren" type="text"
                  maxlength="9"
                  placeholder="XXXXXXXXX"
                  @input="$v.modifyClientPayeurSiren.$touch()"
                  :isValid="$v.modifyClientPayeurSiren.$dirty ? !$v.modifyClientPayeurSiren.$error : null"
                  invalid-feedback="Le numéro de SIREN doit être composé de 9 chiffres"
                >
                </CInput>
              </CCol>
              <CCol sm="6">
                <CInput
                label="Représentant légal"
                autocomplete="eclerk-no-autocomplete-input"
                v-model="modifyClientPayeurRepresentantLegal" type="text"
                maxlength="150"
                placeholder="Nom du représentant légal"
                @input="$v.modifyClientPayeurRepresentantLegal.$touch()"
                :isValid="$v.modifyClientPayeurRepresentantLegal.$dirty ? !$v.modifyClientPayeurRepresentantLegal.$error : null"
                invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
                >
                </CInput>
              </CCol>
            </CRow>

            <CInput
              label="Nom du payeur"
              autocomplete="eclerk-no-autocomplete-input"
              v-model="modifyClientPayeurName" type="text"
              maxlength="150"
              placeholder="Nom"
              @input="$v.modifyClientPayeurName.$touch()"
              :isValid="$v.modifyClientPayeurName.$dirty ? !$v.modifyClientPayeurName.$error : null"
              invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
            >
            </CInput>

            <form autocomplete="eclerk-no-autocomplete-input">
              <label for="eclerk-modify-payeur-client-address">Adresse du payeur</label>
              <vue-google-autocomplete
                autocomplete="eclerk-no-autocomplete-input"
                ref="eclerkModifyClientPayeurAddress"
                id="eclerk-modify-payeur-client-address"
                name="eclerk-modify-payeur-client-address"
                classname="form-control"
                maxlength="299"
                placeholder="Rechercher l'adresse"
                @placechanged="chooseGoogleMapsModifyClientPayeurAddress"
                @inputChange="modifyClientPayeurAddress = $event.newVal"
                :enable-geolocation='true'
              >
              </vue-google-autocomplete>
            </form>
          </div>

          <CRow  v-if="client.is_active" class="mt-4">
            <CCol sm="6">
              <CButton
                :color="modifyClientButtonStyle"
                shape="pill" block class="px-4"
                @click='modifyClient'
                :disabled="isInvalidModifyClient || modifyClientInProcess">
                <CSpinner size="sm" label="update client spinner" v-if="modifyClientInProcess"></CSpinner>
                {{ modifyClientButtonText }}
              </CButton>
            </CCol>
            <CCol sm="6">
              <CButton
                :color="archiveClientButtonStyle"
                shape="pill" block class="px-4"
                @click='archiveClient'
                :disabled="archiveClientInProcess">
                <CSpinner size="sm" label="archive client spinner" v-if="archiveClientInProcess"></CSpinner>
                {{ archiveClientButtonText }}
              </CButton>
              <small class="text-danger">Attention, si vous archivez ce client, aucun collaborateur ne pourra plus le modifier, ni créer de contacts ou missions en lien avec.</small>
            </CCol>
          </CRow>
          <CRow  v-else class="mt-4">
            <CCol class="text-center">
              <strong> Votre client est désactivé</strong>
            </CCol>
          </CRow>
        </CForm>

      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { stripeSoloProduct, stripeSmallProduct, stripeMediumProduct } from '@/variables/localVariables'

import { validationMixin } from 'vuelidate'
import userMixins from '@/mixins/userMixins'
import renderMixins from '@/mixins/renderMixins'
import { required, minLength, maxLength, numeric, requiredIf, email} from 'vuelidate/lib/validators'
import { isStringIntegerOrBlank } from '@/validators/validators'

import { APIUserConnected } from '@/api/APIUserConnected'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import VueGoogleAutocomplete from 'vue-google-autocomplete'

const apiUserConnected = new APIUserConnected()


export default {
  name: 'ClientDetails',
  components: {
    Loading,
    VueGoogleAutocomplete,
  },
  mixins: [
    validationMixin,
    userMixins,
    renderMixins
  ],
  data: function () {
    return {

      // ---- CLIENT ---------
      isClientLoading: false,
      client: {
        collaborateur: {
          id: '',
          full_name: ''
        },
        is_domiciliation: false,
        address: '',
        email: '',
        id: '',
        is_firm: true,
        name: '',
        first_name: '',
        last_name: '',
        phone_country_code: '',
        phone_number: '',
        representant_legal: '',
        siren: '',
        is_different_payeur: false,
        is_payeur_firm: true,
        payeur_siren: '',
        payeur_representant_legal: '',
        payeur_name: '',
        payeur_address: '',
        user: null
      },

      // ---- Modify Client ---
      isClientModified: false,

      modifyClientIsFirm: 'false',
      modifyClientSiren: '',
      modifyClientFormeSociale: '',
      modifyClientRepresentantLegal: '',
      modifyClientName: '',
      modifyClientFirstName: '',
      modifyClientLastName: '',
      modifyClientEmail: '',
      modifyClientPhoneNumber: null,
      modifyClientPhoneNumberResult: {
        countryCode: "FR",
      },
      modifyClientIsDomiciliation: 'false',
      modifyClientAddress: '',

      modifyClientIsDifferentPayeur: 'true',
      modifyClientPayeurIsFirm: 'true',
      modifyClientPayeurSiren: '',
      modifyClientPayeurRepresentantLegal: '',
      modifyClientPayeurName: '',
      modifyClientPayeurAddress: '',

      clientIsFirmArrayForm: [
        { label: 'Une personne morale', value: 'true' },
        { label: "Une personne physique", value: 'false' },
      ],

      modifyClientFormeSocialeArrayOptions: [
        'Entreprise individuelle',
        "Entreprise unipersonnelle à responsabilité limitée",
        "Société à responsabilité limitée",
        "Société par actions simplifiée",
        "Société en nom collectif",
        "Société coopérative de production",
        "Société civile professionnelle",
      ],

      clientIsDomiciliationArrayForm: [
        { label: 'Privée', value: 'false' },
        { label: "De domiciliation", value: 'true' },
      ],

      booleanArrayForm: [
        { label: 'Oui', value: 'true' },
        { label: "Non", value: 'false' },
      ],

      modifyClientButtonText: 'Modifier votre client',
      modifyClientButtonStyle: 'outline-primary',
      modifyClientInProcess: false,

      // ---- Archive Clients ---
      isClientArchived: false,
      archiveClientButtonText: 'Archiver votre client',
      archiveClientButtonStyle: 'outline-danger',
      archiveClientInProcess: false,

      // ---- Activate Clients ---
      isClientActivated: false,
      activateClientButtonText: 'Réactiver votre client',
      activateClientButtonStyle: 'primary',
      activateClientInProcess: false,

      // ---- Contacts linked to clients ----
      contacts: [],
      contactsRender: [],
      contactsRenderFields: [
        { key: "first_name", label: "Prénom", tdClass: 'ui-helper-center', sortable: true},
        { key: "last_name", label: "Nom", tdClass: 'ui-helper-center', sortable: true},
        { key: "email", label: "Email", tdClass: 'ui-helper-center'},
        { key: "phone_number", label: "Numéro de téléphone", tdClass: 'ui-helper-center'},
        { key: "actions", label: "", tdClass: 'ui-helper-center'},
      ],
      isContactsLoading: false,

      isUnlinkingContactFromClient: false,

      // ---- Missions linked to clients ----
      missions: [],
      missionsRender: [],
      missionsRenderFields: [
        { key: "name", label: "Intitulé mission", tdClass: 'ui-helper-center', _style: "min-width: 200px;", sortable: true},
        { key: "types", label: "Type de mission", tdClass: 'ui-helper-center', _style: "min-width: 200px", sortable: true},
        { key: "referent", label: "Référent mission", tdClass: 'ui-helper-center', _style: "min-width: 150px;" },
        { key: "collaborateurs", label: "Collaborateurs affectés", tdClass: 'ui-helper-center', _style: "min-width: 200px;" },
        { key: "description", label: "Description", tdClass: 'ui-helper-center', _style: "min-width: 200px;" },
      ],
      isMissionsLoading: false,


      // ---- STRIPE -------
      stripeSoloProductId: stripeSoloProduct,
      stripeSmallProductId: stripeSmallProduct,
      stripeMediumProductId: stripeMediumProduct,


      isCreateClientToast: false
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isClientLoading || this.isContactsLoading || this.isMissionsLoading || this.isUnlinkingContactFromClient) {
        return true
      }
      return false
    },
    finalModifyClientSiren () {
      return this.modifyClientSiren.replace(/\s/g,'');
    },
    isInvalidModifyClient () {
      if (this.$v.modifyClientIsFirm.$invalid || this.$v.modifyClientSiren.$invalid || this.$v.modifyClientFormeSociale.$invalid ||
      this.$v.modifyClientName.$invalid || this.$v.modifyClientFirstName.$invalid || this.$v.modifyClientLastName.$invalid ||
      this.$v.modifyClientEmail.$invalid || this.$v.modifyClientAddress.$invalid ||
      this.$v.modifyClientPhoneNumberResult.$invalid || this.$v.modifyClientIsDifferentPayeur.$invalid || this.$v.modifyClientPayeurIsFirm.$invalid ||
      this.$v.modifyClientPayeurSiren.$invalid || this.$v.modifyClientPayeurRepresentantLegal.$invalid ||
      this.$v.modifyClientPayeurName.$invalid || this.$v.modifyClientPayeurAddress.$invalid ) {
        return true
      }
      return false
    },

    companyStripeProductId () {
      return this.$store.state.user.companyStripeProductId
    }
  },
  validations: {
    modifyClientIsFirm: {
      required
    },
    modifyClientSiren: {
      minLength: minLength(11),
      maxLength: maxLength(11),
      validSiren(value) {
        return isStringIntegerOrBlank(value)
      },
      required: requiredIf(function () {
        return this.modifyClientIsFirm =='true'
      }),
    },
    modifyClientRepresentantLegal: {
      minLength: minLength(2),
      maxLength: maxLength(149),
      required: requiredIf(function () {
        return this.modifyClientIsFirm == 'true'
      }),
    },
    modifyClientFormeSociale: {
      minLength: minLength(2),
      maxLength: maxLength(149),
    },
    modifyClientName: {
      minLength: minLength(2),
      maxLength: maxLength(149),
      required: requiredIf(function () {
        return this.modifyClientIsFirm == 'true'
      }),
    },
    modifyClientFirstName: {
      minLength: minLength(2),
      maxLength: maxLength(149),
      required: requiredIf(function () {
        return this.modifyClientIsFirm == 'false'
      }),
    },
    modifyClientLastName: {
      minLength: minLength(2),
      maxLength: maxLength(149),
      required: requiredIf(function () {
        return this.modifyClientIsFirm == 'false'
      }),
    },
    modifyClientEmail: {
      email,
      maxLength: maxLength(249),
      minLength: minLength(2)
    },
    modifyClientPhoneNumberResult: {
      countryCode: {
        minLength: minLength(2),
        maxLength: maxLength(2)
      },
      isValid: {
        isValid(value) {
          if (this.modifyClientPhoneNumber == null) {
            return true
          }
          else {
            return value
          }
        }
      }
    },
    modifyClientIsDomiciliation: {
      required
    },
    modifyClientAddress: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(300)
    },

    modifyClientIsDifferentPayeur: {
      required
    },
    modifyClientPayeurIsFirm: {
      required
    },
    modifyClientPayeurSiren: {
      minLength: minLength(9),
      maxLength: maxLength(14),
      numeric,
      required: requiredIf(function () {
        return this.modifyClientPayeurIsFirm =='true' && this.modifyClientIsDifferentPayeur == 'true'
      }),
    },
    modifyClientPayeurRepresentantLegal: {
      minLength: minLength(2),
      maxLength: maxLength(149),
      required: requiredIf(function () {
        return this.modifyClientPayeurIsFirm == 'true' && this.modifyClientIsDifferentPayeur == 'true'
      }),
    },
    modifyClientPayeurName: {
      maxLength: maxLength(149),
      minLength: minLength(2),
      required: requiredIf(function () {
        return this.modifyClientIsDifferentPayeur == 'true'
      }),
    },
    modifyClientPayeurAddress: {
      minLength: minLength(2),
      maxLength: maxLength(300),
      required: requiredIf(function () {
        return this.modifyClientIsDifferentPayeur == 'true'
      }),
    },
  },

  created: function() {
    this.getClient()
    this.getAllClientContacts()
    this.getAllClientMissions()
  },

  watch: {
    contacts: function (newContacts) {
      if (newContacts.length == 0) {
        this.contactsRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newContacts.length; i++) {
          final_array.push(
            {
              'id': newContacts[i].id,
              'first_name': newContacts[i].first_name,
              'last_name': newContacts[i].last_name,
              'phone_country_code': newContacts[i].phone_country_code,
              'phone_number': newContacts[i].phone_number,
              'email': newContacts[i].email,
            }
          )
        }
        this.contactsRender = final_array
      }
    },
    missions: function (newMissions) {
      if (newMissions.length == 0) {
        this.missionsRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newMissions.length; i++) {
          final_array.push(
            {
              'id': newMissions[i].id,
              'name': newMissions[i].name,
              'types': newMissions[i].types,
              'referent': newMissions[i].referent_full_name,
              'collaborateurs': newMissions[i].collaborateurs,
              'description': newMissions[i].description,

            }
          )
        }
        this.missionsRender = final_array
      }
    },
  },

  methods: {
    // ------------- Getters --------------
    getClient () {
      this.isClientLoading = true
      apiUserConnected.getClient(this.token, this.$route.params.id)
      .then((result) => {
        this.client = result.data
        this.modifyClientIsFirm = String(this.client.is_firm)
        this.modifyClientSiren = this.client.siren
        this.modifyClientRepresentantLegal = this.client.representant_legal
        this.modifyClientFormeSociale = this.client.forme_sociale
        this.modifyClientName = this.client.name
        this.modifyClientFirstName = this.client.first_name
        this.modifyClientLastName = this.client.last_name
        this.modifyClientEmail = this.client.email
        if (this.client.phone_number != '') {
          this.modifyClientPhoneNumberResult = {
            countryCode: this.client.phone_country_code,
            isValid: true,
            nationalNumber: this.client.phone_number
          }
          this.modifyClientPhoneNumber = this.client.phone_number
        }
        this.modifyClientIsDomiciliation = String(this.client.is_domiciliation)
        this.modifyClientAddress = this.client.address
        this.$refs.eclerkModifyClientAddress.update(this.client.address)


        this.modifyClientIsDifferentPayeur = String(this.client.is_different_payeur)
        this.modifyClientPayeurIsFirm = String(this.client.is_payeur_firm)
        this.modifyClientPayeurSiren = this.client.payeur_siren
        this.modifyClientPayeurRepresentantLegal = this.client.payeur_representant_legal
        this.modifyClientPayeurName = this.client.payeur_name
        this.modifyClientPayeurAddress = this.client.payeur_address

        if (this.modifyClientIsDifferentPayeur == 'true') {
          this.$refs.eclerkModifyClientPayeurAddress.update(this.client.payeur_address)
        }

        //if ((this.companyStripeProductId == this.stripeSmallProductId || this.companyStripeProductId == this.stripeMediumProductId) && !this.client.user && this.userGroupContains(['MANAGER']) && this.client.is_active) {
        //  this.isCreateClientToast = true
        //}
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isClientLoading = false
      })
    },

    getAllClientContacts () {
      this.isContactsLoading = true
      apiUserConnected.getAllClientContacts(this.token, this.$route.params.id)
      .then((result) => {
        this.contacts = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isContactsLoading = false
      })
    },

    getAllClientMissions () {
      this.isMissionsLoading = true
      apiUserConnected.getClientAllMissions(this.token, this.$route.params.id)
      .then((result) => {
        this.missions = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isMissionsLoading = false
      })
    },

    // ---------- Modify client -----------
    modifyClient () {
      this.modifyClientButtonText = 'Modification en cours'
      this.modifyClientButtonStyle = 'secondary'
      this.modifyClientInProcess = true
      apiUserConnected.modifyClient(this.token,
        this.client.id, this.modifyClientIsFirm, this.finalModifyClientSiren, this.modifyClientFormeSociale, this.modifyClientRepresentantLegal,
        this.modifyClientName, this.modifyClientFirstName, this.modifyClientLastName,
        this.modifyClientEmail, this.modifyClientPhoneNumberResult.countryCode, this.modifyClientPhoneNumberResult.nationalNumber,
        this.modifyClientIsDomiciliation, this.modifyClientAddress, this.modifyClientIsDifferentPayeur, this.modifyClientPayeurIsFirm,
        this.modifyClientPayeurSiren, this.modifyClientPayeurRepresentantLegal, this.modifyClientPayeurName, this.modifyClientPayeurAddress)
      .then(() => {
        this.isClientModified = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.modifyClientButtonText = 'Modifier votre client'
        this.modifyClientButtonStyle = 'outline-primary'
        this.modifyClientInProcess = false
      })
    },

    chooseGoogleMapsModifyClientAddress: function (addressData) {
      this.modifyClientAddress = addressData['street_number'] + ', ' + addressData['route'] + ', ' + addressData['locality'] + ', ' + addressData['country'];
    },

    chooseGoogleMapsModifyClientPayeurAddress: function (addressData) {
      this.modifyClientPayeurAddress = addressData['street_number'] + ', ' + addressData['route'] + ', ' + addressData['locality'] + ', ' + addressData['country'];
    },

    // ---------- Archive client -----------
    archiveClient () {
      this.archiveClientButtonText = 'Archivage en cours'
      this.archiveClientButtonStyle = 'secondary'
      this.archiveClientInProcess = true
      apiUserConnected.archiveClient(this.token,
        this.client.id)
      .then(() => {
        this.getClient()
        this.isClientArchived = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.archiveClientButtonText = 'Archiver votre client'
        this.archiveClientButtonStyle = 'outline-danger'
        this.archiveClientInProcess = false
      })
    },

    // ---------- ACTIVATE client -----------
    activateClient () {
      this.activateClientButtonText = 'Activation en cours'
      this.activateClientButtonStyle = 'secondary'
      this.activateClientInProcess = true
      apiUserConnected.activateClient(this.token, this.client.id)
      .then(() => {
        this.getClient()
        this.isClientActivated = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.activateClientButtonText = 'Réactiver votre client'
        this.activateClientButtonStyle = 'primary'
        this.activateClientInProcess = false
      })
    },


    unlinkContactFromClient (contact_pk) {
      this.isUnlinkingContactFromClient = true
      apiUserConnected.unlinkContactFromClient(this.token, contact_pk)
      .then(() => {
        this.getAllClientContacts()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isUnlinkingContactFromClient = false
      })
    },

    // ---------- Router -----------------
    goToContactPage(item) {
      this.$router.push('/contact-details/' + item.id)
    },
    goToMissionPage(item) {
      this.$router.push('/mission/' + item.id)
    },
    goToAccessClient() {
      var routeData = this.$router.resolve('/visualisation/access-client/' + this.client.id);
      window.open(routeData.href, '_blank');
    }
  }
}
</script>

<style>
.toast {
  flex-basis: auto !important;
}
.custom-toast {
  background-color: white;
}
</style>
